
import Field from "@/components/crud/fields/FieldMixin.vue";
import Vue from "vue";

export default Vue.extend({
  name: "KAutocomplete",
  mixins: [Field],
  inheritAttrs: false,
  props: {
    clearable: {
      type: Boolean,
      default: true,
    },
  },
});
