import { get } from "@/application/api/index";
import { convertCamelToSnake } from "@/application/util/keyConverter";
import { AxiosResponse } from "axios";

export interface PaginatedResponse<T = unknown> {
  data: T;
  meta: {
    currentPage: number;
    from: number;
    lastPage: number;
    perPage: number;
    to: number;
    total: number;
  };
}

export interface PaginatedRequest {
  page?: number;
  perPage?: number;
  search?: string;
  sortBy?: string;
  descending?: boolean;
  params?: Record<string, unknown>;
}

export const getPaginated = (
  url: string,
  data: PaginatedRequest
): Promise<AxiosResponse<PaginatedResponse<any>>> => {
  return get(url, {
    params: {
      page: data.page || 1,
      perPage: data.perPage || 10,
      ...(data.params || {}),
      search: data.search || undefined,
      sortBy: data.sortBy ? convertCamelToSnake(data.sortBy) : undefined,
      desc:
        typeof data.descending !== "undefined"
          ? data.descending
            ? "1"
            : "0"
          : undefined,
    },
  });
};
